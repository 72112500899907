<script setup lang="ts">

import {useAuthStore} from "../../store/authStore";
import {useTableFilterStorage} from "../../store/tableFilterStorage";

const authStore = useAuthStore()
const router = useRouter();


const items = [
  [{
    label: authStore.email,
    slot: 'account',
    disabled: true
  }], [{
    label: 'Settings',
    icon: 'i-heroicons-cog-8-tooth',
    click: () => {
      return navigateTo({
        path: '/settings/user',
      })
    }
  }], [{
    label: 'Documentation',
    icon: 'i-heroicons-book-open',
    to: '/whathappenedlastnight'
  }, {
    label: 'Changelog',
    icon: 'i-heroicons-megaphone',
    to: '/whathappenedlastnight'
  },], [{
    label: 'Sign out',
    icon: 'i-heroicons-arrow-left-on-rectangle',
    click: () => {
      const authStore = useAuthStore();
      authStore.logout(); // Assuming your auth store has a logout method
      const tableFilterStore = useTableFilterStorage();
      tableFilterStore.reset();
      router.push("/");
    }
  }]
]
</script>

<template>
  <UDropdown :items="items" :ui="{ width: 'w-full', item: { disabled: 'cursor-text select-text' } }"
             :popper="{ strategy: 'absolute', placement: 'top' }"
             class="w-full"
             mode="hover">


    <template #default="{ open }">
      <UButton
        color="gray"
        variant="ghost"
        class="w-full"
        :label="authStore.email"
        :class="[open && 'bg-gray-50 dark:bg-gray-800']"
      >
        <template #leading>
          <UAvatar size="2xs" v-if="authStore.avatarUrl != null && authStore.avatarUrl !=''"
                   :src="authStore.avatarUrl"/>
          <UAvatar size="2xs" v-else
                   src="https://avatars.githubusercontent.com/u/739984?v=4"/>
        </template>

        <template #trailing>
          <UIcon
            name="i-heroicons-ellipsis-vertical"
            class="w-5 h-5 ml-auto"
          />
        </template>
      </UButton>
    </template>


    <template #account="{ item }">
      <div class="text-left">
        <p>
          Signed in as
        </p>
        <p class="truncate font-medium text-gray-900 dark:text-white">
          {{ item.label }}
        </p>
      </div>
    </template>

    <template #item="{ item }">
      <span class="truncate">{{ item.label }}</span>

      <UIcon :name="item.icon" class="flex-shrink-0 h-4 w-4 text-gray-400 dark:text-gray-500 ms-auto"/>
    </template>
  </UDropdown>
</template>

