<script setup lang="ts">
import type {NavItem} from '@nuxt/content/dist/runtime/types'
//import type { NavItem } from '@nuxt/content'
import ProfileAvatar from "./avatar/ProfileAvatar.vue";

const navigation = inject<Ref<NavItem[]>>('navigation', ref([]))
const authStore = useAuthStore()

const links = [{
  label: 'Home',
  icon: 'i-heroicons-home',
  to: '/'
}, {
  label: 'Navigation',
  icon: 'i-heroicons-square-3-stack-3d'
}, {
  label: 'Breadcrumb',
  icon: 'i-heroicons-link'
}]

</script>

<template>
  <UHeader :links="filteredLinks">
    <template #logo>
      {{ authStore.isAuthenticated ? authStore.companyName : 'Candidate' }}
      <UBadge :label="authStore.isAuthenticated ? 'Candidate' : 'SaaS'" variant="subtle" class="mb-0.5"/>
    </template>


    <template #right>
      <UButton v-if="!authStore.isAuthenticated" label="Log in" color="gray" to="/login"/>
      <UButton v-if="!authStore.isAuthenticated" label="Sign up" icon="i-heroicons-arrow-right-20-solid" trailing
               color="black" to="/signup"
               class="hidden lg:flex"/>
      <div v-if="authStore.isAuthenticated">
        <ProfileAvatar/>
      </div>

    </template>

    <template #panel>
      <UNavigationTree :links="mapContentNavigation(navigation)" default-open/>
    </template>
  </UHeader>
</template>

<script lang="ts">
import {useAuthStore} from "~/store/authStore";


export default {
  data() {
    return {
      links: [
        {
          label: 'Pricing',
          to: '/pricing',
        },
        {
          label: 'Blog',
          to: '/blog',
        },
        {
          label: 'Dashboard',
          to: '/dashboard',
          authenticationRequired: true,
        },
        {
          label: 'Monitor',
          to: '/monitor',
          authenticationRequired: true,
        },
        {
          label: 'CV-Parser',
          to: '/cvparser',
          authenticationRequired: true,
        },
        {
          label: 'Candidates',
          to: '/candidates',
          authenticationRequired: true,
        },
        {
          label: 'What happened last night?',
          to: '/whathappenedlastnight',
          authenticationRequired: true,
        }
      ],
    };
  },
  computed: {
    filteredLinks() {
      const authStore = useAuthStore();

      // Return only the 'Docs' link when the user is authenticated
      if (authStore.isAuthenticated) {
        return this.links.filter(value => value && value.authenticationRequired);
      } else {
        return this.links.filter(value => value && !value.authenticationRequired);
      }
    },
  },
  methods: {
    logout() {
      const authStore = useAuthStore();
      authStore.logout(); // Assuming your auth store has a logout method
      const router = useRouter();
      router.push("/");
    },
  },
};
</script>
