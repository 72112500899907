import {defineStore} from 'pinia';
import {persistedState} from "../.nuxt/imports";
import {jwtDecode} from "jwt-decode";


interface AuthState {
  isAuthenticated: boolean;
  token: string | null;
  refresh: string | null;
}

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => {
    return {
      token: '',
      refresh: '',
      isAuthenticated: false,
      avatarUrl:'',
      email: '',
      companyName: '',
      companyId: '',
      role: '',
      userUuid: '',
      validUntil: 0
    }
  },
  persist: {
    storage: persistedState.localStorage,
  },
  actions: {
    authenticate(token: string, refresh: string) {
      this.token = token;
      this.isAuthenticated = token != null && token != '';
      this.refresh = refresh;
      const decoded = jwtDecode(token);
      console.log("decoded payload: ", decoded);
      this.userUuid = decoded.sub;
      this.role = decoded.ROLE;
      this.email = decoded.MAIL;
      this.avatarUrl = decoded.AVATAR_URL;
      this.companyName = decoded.COMPANY_NAME;
      this.companyId = decoded.COMPANY_ID;
      this.validUntil = decoded.exp;
      const { $websocket } = useNuxtApp();

    },
    logout() {
      this.token = '';
      this.isAuthenticated = false;
      this.refresh = '';
      this.email = '';
      this.companyName = '';
      this.companyId = '';
      this.avatarUrl = '';
      this.role = '';
      this.userUuid = '';
      this.validUntil = 0;
    },
  },
});
