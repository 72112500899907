import {defineNuxtPlugin} from '#app';
import {useAuthStore} from "~/store/authStore";

export default defineNuxtPlugin((nuxtApp) => {
  const authStore = useAuthStore()

  const isLoggedIn = () => {

    // Implement your authentication check logic here
    // This could involve checking a token or user state
    return Boolean(authStore.isAuthenticated); // Replace with your auth logic
  };

  nuxtApp.hook('page:meta', (meta) => {
    if (isLoggedIn()) {
      meta.layout = 'dashboard';
    }
  });
});
