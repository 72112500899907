<script setup lang="ts">
const colorMode = useColorMode()

const color = computed(() => colorMode.value === 'dark' ? '#111827' : 'white')

useHead({
  meta: [
    {charset: 'utf-8'},
    {name: 'viewport', content: 'width=device-width, initial-scale=1'},
    {key: 'theme-color', name: 'theme-color', content: color}
  ],
  link: [
    {rel: 'icon', href: '/icononly_transparent_nobuffer.png'}
  ],
  htmlAttrs: {
    lang: 'en'
  },
  script: [
    { defer: true,
      src: 'https://cloud.umami.is/script.js',
      'data-website-id': "382713ca-85e8-409b-84d5-e7de0f64e634"
    },
    { src: "script.js" }
  ]
})

useSeoMeta({
  titleTemplate: 'Candidate - Recruiting - HR', //'%s - Candidate - Recruiting - HR',
  ogSiteName: 'Candidate - Tool Nr.1 für Arbeitnehmerüberlassungen',
  twitterCard: 'summary_large_image'
})
</script>

<template>
  <div>
    <NuxtLoadingIndicator/>

    <NuxtLayout>
      <NuxtPage/>
    </NuxtLayout>

    <UNotifications/>
  </div>
</template>
