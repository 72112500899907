import revive_payload_client_4sVQNw7RlN from "/workspace/candidate/ui/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/workspace/candidate/ui/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/workspace/candidate/ui/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_9gMxCtyFUG from "/workspace/candidate/ui/node_modules/nuxt-site-config/dist/runtime/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/workspace/candidate/ui/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/workspace/candidate/ui/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/workspace/candidate/ui/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/workspace/candidate/ui/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/workspace/candidate/ui/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import modals_bidRKewKK5 from "/workspace/candidate/ui/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_244lXBzhnM from "/workspace/candidate/ui/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_i8AMfKeYnY from "/workspace/candidate/ui/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import plugin_client_j7wLaRg9eH from "/workspace/candidate/ui/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/workspace/candidate/ui/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_1UohGbtF8v from "/workspace/candidate/ui/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import presets_1aypKNZ222 from "/workspace/candidate/ui/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import scrollbars_client_7kPaTs6T4H from "/workspace/candidate/ui/node_modules/@nuxt/ui-pro/plugins/scrollbars.client.ts";
import variables_kQtglGecod from "/workspace/candidate/ui/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import ofetch_TmnSnS1Swe from "/workspace/candidate/ui/plugins/ofetch.ts";
import pinia_48xmdi2HHl from "/workspace/candidate/ui/plugins/pinia.ts";
import auth_layout_client_pwrhYxIKhF from "/workspace/candidate/ui/plugins/auth-layout.client.ts";
import websocket_E4fTueNpBJ from "/workspace/candidate/ui/plugins/websocket.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_9gMxCtyFUG,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  modals_bidRKewKK5,
  colors_244lXBzhnM,
  plugin_client_i8AMfKeYnY,
  plugin_client_j7wLaRg9eH,
  chunk_reload_client_UciE0i6zes,
  plugin_1UohGbtF8v,
  presets_1aypKNZ222,
  scrollbars_client_7kPaTs6T4H,
  variables_kQtglGecod,
  ofetch_TmnSnS1Swe,
  pinia_48xmdi2HHl,
  auth_layout_client_pwrhYxIKhF,
  websocket_E4fTueNpBJ
]