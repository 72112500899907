import {defineNuxtPlugin} from '#app';
import {ref, Ref} from 'vue';
import {useAuthStore} from "../store/authStore";
import {useToast} from "../.nuxt/imports";

interface WebSocketPlugin {
  notifications: Ref<string[]>;
  socket: WebSocket | null;
}

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()

  const notifications = ref<string[]>([]);
  let socket: WebSocket | null = null;
  const toast = useToast();
  const ERROR_STATUS = ['DUPLICATE', 'ERROR', 'LIMIT_EXCEEDED'];
  const SUCCESS_STATUS = ['PROCESSED', 'PERSISTED'];

  const connectWebSocket = () => {
    const authStore = useAuthStore();

    if (!authStore.isAuthenticated) {
      console.error("User ID not found in localStorage");
      return;
    }


    socket = new WebSocket(`${config.public.baseURL.replace('https://', 'wss://').replace('http://', 'wss://')}/notifications?uuid=${authStore.userUuid}`);

    socket.onmessage = (event: MessageEvent) => {
      console.log("Received message: ", event.data);
      notifications.value.push(event.data);
      handleNotification(event.data);
    };

    socket.onerror = (error: Event) => {
      console.error("WebSocket error: ", error);
    };

    socket.onclose = () => {
      console.log("WebSocket connection closed");
      socket = null;
    };
  };

  // Function to handle received notifications
  const handleNotification = (notificationString) => {
    const notification = JSON.parse(notificationString);

    toast.add({
      title: 'CV - Processing Status: ' + notification.status,
      description: 'Status: ' + notification.status + ' für "' + notification.filename + '"',
      color: ERROR_STATUS.includes(notification.status) ? 'red' : SUCCESS_STATUS.includes(notification.status) ? 'green' : 'yellow',
      timeout: 30000
    })
    // Trigger a Nuxt event, Vue's reactivity, or directly call your notification method
    //nuxtApp.vueApp.config.globalProperties.$showNotification(notification);
  };


  connectWebSocket();

  nuxtApp.provide('websocket', {
    notifications,
    socket
  } as WebSocketPlugin);
});
