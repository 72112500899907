// middleware/redirectIfLoggedIn.global.ts
import { defineNuxtRouteMiddleware, navigateTo } from '#app';
import {useAuthStore} from "../store/authStore";

export default defineNuxtRouteMiddleware((to) => {
  const authStore = useAuthStore()

  const isLoggedIn = () => {

    // Implement your authentication check logic here
    // This could involve checking a token or user state
    return Boolean(authStore.isAuthenticated); // Replace with your auth logic
  };


  // If the user is logged in and tries to access the root page
  if (isLoggedIn() && to.path === '/') {
    // Redirect to the dashboard
    return navigateTo('/dashboard');
  }
});
