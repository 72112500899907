import {ofetch} from 'ofetch'
import {useAuthStore} from "~/store/authStore";


export default defineNuxtPlugin((_nuxtApp) => {
  let refreshAttempts = 0;
  const config = useRuntimeConfig()
  const authStore = useAuthStore()
  const router = useRouter();

  async function refreshToken() {
    try {
      if (refreshAttempts >= 6) {
        console.log("REFRESH COUNTER IS GT 6... TO MANY FAILED ATTEMPTS");
        return;
      }
      refreshAttempts++; // increment attempts counter
      const refreshResponse = await (fetch(config.public.baseURL + 'v1/auth/refresh', {
        method: 'POST',
        body: JSON.stringify({
          'refreshToken': authStore.refresh
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }));
      console.log("status is: ", refreshResponse.status);
      //const responseData = await refreshResponse.json();

      //console.log("responseData: ", responseData);
      if (refreshResponse.status == 200) {
        // successful refresh
        refreshAttempts = 0; // reset attempts counter on successful refresh
        //console.log("refreshResponse: ", refreshResponse);
        const data = await refreshResponse.json();

        //console.log("refreshResponse.json(): ", data);

        //console.log("OLD auth store, token: ", authStore.token, ", refresh: ", authStore.refresh)
        authStore.authenticate(data.bearerToken, data.refreshToken);
        //console.log("AUTHENTICATE auth store, token: ", authStore.token, ", refresh: ", authStore.refresh)
        authStore.$persist()
        //console.log("Auth store is refreshed.");
        //console.log("Original request is: ", context.request);
        //console.log("PERSIST auth store, token: ", authStore.token, ", refresh: ", authStore.refresh)

        authStore.$hydrate({runHooks: false});
        //console.log("HYDRATE auth store, token: ", authStore.token, ", refresh: ", authStore.refresh)
        refreshAttempts = 0;

      } else {
        console.error('Failed to refresh token');
      }
    } catch (error) {
      console.error('Refresh error', error)
    }
  }

  globalThis.$authFetch = ofetch.create({
    baseURL: config.public.baseURL,
    onRequest: async ({request, options}) => {
      if (request.startsWith('/api/')) {
        // add a special case for handling /api/ requests
        console.log("_api request is: ", request);
        // modify options as needed for api requests
        return;
      }
      console.log("BASE_URL:", config.public.baseURL)
      console.log("_request is: ", request);
      console.log("options are: ", options);

      options.headers = options.headers || {};

      console.log("HEADERS ARE: ",options.headers)
      if (authStore.isAuthenticated) {
        if (authStore.validUntil == null || authStore.validUntil * 1000 - Date.now() - 60000 < 0) {
          console.log("REFRESHING TOKEN..... ");
          await refreshToken();
        }
        options.headers.Authorization = `Bearer ${authStore.token}`;
        //options.headers.Authorization = {Authorization: `Bearer ${authStore.token}`}
        console.log("Added header: ", options.headers)
      } else {
        //options.headers = {testHeader: `Bearer eyBearerToken`}
        options.headers.testHeader = `Bearer eyBearerToken`;
        console.log('Not authenticated', options.headers)
      }
    },
    onResponse: async (context) => {
      if (context.response.status === 401 && authStore.refresh != '' && refreshAttempts < 6) {
        try {
          await refreshToken();
          return $authFetch(context.request, context.options); // retry original request
        } catch (error) {
          console.error('Refresh error', error)
        }
      } else if (context.response.status == 403 || refreshAttempts >= 6) {
        authStore.logout();
        await router.push("/");
      } else {
        console.log(context)
      }
    },
    onRequestError({error}) {
      console.error("onRequestError caled: ", error);
    }
  })
})
